// @ts-nocheck - getHeadingStyle doesnt work
import {
  DefaultTheme,
  Heading as HeadingChakra,
  HeadingProps,
} from "@chakra-ui/react";
import React from "react";
import * as theme from "lib/styles/theme";

export enum HeadingType {
  HEADING = "heading",
  HEADING_MED = "headingMed",
  HEADING_MED_ACTIVE = "headingMedActive",
  HEADING_MED_SUCCESS = "headingMedSuccess",
  HEADING_MED_LIGHT = "headingMedLight",
  HEADING_SMALL = "headingSmall",
  HEADING_SMALL_LIGHT = "headingSmallLight",
  HEADING_SMALL_LIGHT_LABEL = "headingSmallLightLabel",
}

interface OwnProps {
  // defaults to H3
  type?: HeadingType;
  children: React.ReactNode;
}

type Props = HeadingProps & OwnProps;

/**
 * Generic Chakra Heading wrapper to allow easy styleguide matching / updates
 * @deprecated - use Text and TextType
 * @param props
 */
export default function Heading(props: Props): JSX.Element {
  const { type, children, ...headingProps } = props;
  const genericHeadingStyle = getHeadingStyle(type);
  const textColor = getTextColor(type);
  return (
    <HeadingChakra
      {...genericHeadingStyle}
      {...headingProps}
      textColor={props.color || props.textColor || textColor}
      textTransform={props.textTransform || undefined}
    >
      {children}
    </HeadingChakra>
  );
}

function getTextColor(
  type: TextType
): ColorProps.textColor | string | undefined {
  switch (type) {
    case HeadingType.HEADING_MED_ACTIVE:
      return theme.colors.brand.Branding;
    case HeadingType.HEADING_MED_SUCCESS:
      return theme.colors.brand.SuccessText;
    case HeadingType.HEADING:
    case HeadingType.HEADING_MED:
    case HeadingType.HEADING_MED_LIGHT:
    case HeadingType.HEADING_SMALL:
    case HeadingType.HEADING_SMALL_LIGHT:
      return theme.colors.textAndShapes.BrandBlack;
    case HeadingType.HEADING_SMALL_LIGHT_LABEL:
      return theme.colors.textAndShapes.InputDisabledLabelText;
    default:
      return undefined;
  }
}

/**
 * Format the Heading per the style guide,
 * Can add additional style props on top
 * @param type - TextType matches stylguide naming
 */
function getHeadingStyle(type: HeadingType = HeadingType.HEADING_MED): {
  fontWeight: keyof DefaultTheme["fontWeights"];
  fontSize: keyof DefaultTheme["fontSizes"];
  lineHeight: keyof DefaultTheme["lineHeights"] | "shortest" | "tallest";
  letterSpacing: keyof DefaultTheme["letterSpacings"];
} {
  switch (type) {
    case HeadingType.HEADING:
      return {
        fontSize: "5xl",
        fontWeight: "normal",
        letterSpacing: "normal",
        lineHeight: "tallest",
      };
    case HeadingType.HEADING_MED:
    case HeadingType.HEADING_MED_ACTIVE:
    case HeadingType.HEADING_MED_SUCCESS:
      return {
        fontSize: "2xl",
        fontWeight: "400", //"600",
        letterSpacing: "normal",
        lineHeight: "taller",
      };
    case HeadingType.HEADING_MED_LIGHT:
      return {
        fontSize: "2xl",
        fontWeight: "400",
        letterSpacing: "normal",
        lineHeight: "tallest",
        opacity: "70%",
      };
    case HeadingType.HEADING_SMALL:
      return {
        fontSize: "xl",
        fontWeight: "medium",
        letterSpacing: "normal",
        lineHeight: "taller",
      };
    case HeadingType.HEADING_SMALL_LIGHT:
    case HeadingType.HEADING_SMALL_LIGHT_LABEL:
      return {
        fontSize: "xl",
        fontWeight: "light",
        letterSpacing: "normal",
        lineHeight: "taller",
      };
  }
}
